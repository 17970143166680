// Generated by Framer (10960d4)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Noise from "https://framerusercontent.com/modules/bamMgJhjMszHbcnxCtZj/20nHFbpxFqdlchR1dDZF/Noise.js";
const NoiseFonts = getFonts(Noise);

const cycleOrder = ["Qp1lvHyz3"];

const serializationHash = "framer-KeMoU"

const variantClassNames = {Qp1lvHyz3: "framer-v-6srcp6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Qp1lvHyz3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KeMoU", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-6srcp6", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Qp1lvHyz3"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1022cxs-container"} data-framer-name={"Noise Background"} layoutDependency={layoutDependency} layoutId={"w0fB3y8yR-container"} name={"Noise Background"}><Noise backgroundSize={128} borderRadius={0} height={"100%"} id={"w0fB3y8yR"} layoutId={"w0fB3y8yR"} name={"Noise Background"} opacity={0.1} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KeMoU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KeMoU .framer-1y0teet { display: block; }", ".framer-KeMoU .framer-6srcp6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-KeMoU .framer-1022cxs-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KeMoU .framer-6srcp6 { gap: 0px; } .framer-KeMoU .framer-6srcp6 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-KeMoU .framer-6srcp6 > :first-child { margin-left: 0px; } .framer-KeMoU .framer-6srcp6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerN8E8ewa9l: React.ComponentType<Props> = withCSS(Component, css, "framer-KeMoU") as typeof Component;
export default FramerN8E8ewa9l;

FramerN8E8ewa9l.displayName = "Noise";

FramerN8E8ewa9l.defaultProps = {height: 800, width: 1200};

addFonts(FramerN8E8ewa9l, [...NoiseFonts])